import { AxiosInstance } from "axios";

const url = "/file";

export default function(configedRequest: AxiosInstance) {
  return {
    async get(name: string, path: string) {
      const params = {
        path,
        imageName: name
      };
      const { data } = await configedRequest.get<any>(`${url}`, { params });
      return data;
    },
    async save(file: File) {
      const body = new FormData();
      body.append("file", file);
      const { data } = await configedRequest.post(`${url}`, body, {
        maxContentLength: Infinity,
        maxBodyLength: Infinity
      });
      return data;
    },
    async getTemplateCertificate(name: string) {
      const params = {
        imageName: name
      };
      const { data } = await configedRequest.get<any>(`${url}/certificate-template`, { params });
      return data;
    },
    async saveTemplateCertificate(name: string, file: any) {
      const body = new FormData();
      body.set("file", file);
      const { data } = await configedRequest.post(
        `${url}/certificate-template?imageName=${name}`,
        body,
        {
          maxContentLength: Infinity,
          maxBodyLength: Infinity
        }
      );
      return data;
    },
    async saveConfidential(type: string, file: File) {
      const body = new FormData();
      body.set("file", file);
      const { data } = await configedRequest.post(
        `${url}/confidential?fileType=${type}`,
        body,
        {
          maxContentLength: Infinity,
          maxBodyLength: Infinity
        }
      );
      return data;
    }
  };
}
