import { NavigationGuard, RouteConfig } from "vue-router";
import App from "../views/App/index.vue";
import jwtDecode from "jwt-decode";
import { appRoute } from "@/shared/constants";
import store from "@/store";
import { AppApiFactory } from "@/services/AppFactory";
import { Token } from "@/store/auth";
const profileService = AppApiFactory.profile;

const authGuard: NavigationGuard<Vue> = (to, from, next) => {
  const checkmember = localStorage.getItem(process.env.VUE_APP_APP_KEY);
  if (checkmember) {
    const decodedToken = jwtDecode<Token>(checkmember);
    if (decodedToken.account_role.permission_code === "A_MEMBER") {
      localStorage.removeItem(process.env.VUE_APP_ADMIN_KEY);
    }
  }

  store.dispatch("checkAppAuth").then(() => {
    const isAuthen = store.getters.isAppAuthen;
    if (isAuthen) {
      next();
    } else {
      window.location.href = process.env.VUE_APP_SSO_PATH;
    }
  });
};

const checkRegister: NavigationGuard<Vue> = (to, from, next) => {
  const isFirstTime = store.getters.isFirstTime;

  profileService.get().then(res => {
    if (res.data !== null) {
      authGuard(to, from, next);
      store.dispatch("checkFirstTime", false);
      next();
    } else if (res.data !== null && to.path.includes("/history")) {
      authGuard(to, from, next);
    } else if (res.data === null) {
      if (isFirstTime === false) {
        store.dispatch(
          "showErrorSnackbar",
          "Please complete registration before proceeding to the next step"
        );
      } else {
        store.dispatch("checkFirstTime", true);
      }
      // if (to.path === "/") {
      //   next("/");
      // }
    }
  });

  // if (!isFirstTime) {
  //     profileService.get().then(res => {
  //         if (res.data !== null) {
  //             authGuard(to, from, next);
  //             store.dispatch("checkFirstTime", false);
  //             next();
  //         } else if (res.data !== null && to.path.includes("/history")) {
  //             authGuard(to, from, next);
  //         } else if (res.data === null) {
  //             if (isFirstTime === false) {
  //                 store.dispatch(
  //                     "showErrorSnackbar",
  //                     "Please complete registration before proceeding to the next step"
  //                 );
  //             } else {
  //                 store.dispatch("checkFirstTime", true);
  //             }
  //             if (to.path === "/") {
  //                 next("/register");
  //             }
  //         }
  //     });
  // }

  next();
};

const ssoGuard: NavigationGuard<Vue> = async (to, from, next) => {
  const token = to.query.token as string;

  if (token) {
    await localStorage.setItem(process.env.VUE_APP_APP_KEY, token);
    //console.log("sso", token);

    store.dispatch("checkAppAuth").then(() => {
      next("/");
    });
  } else {
    window.location.href = process.env.VUE_APP_SSO_PATH;
  }
};

export const appRoutes: Array<RouteConfig> = [
  {
    path: "/test",
    name: "test",
    component: () => import("../views/Test.vue")
  },

  {
    path: "/sso",
    name: "appsso",
    beforeEnter: ssoGuard
  },
  {
    path: "/",
    component: App,

    beforeEnter: checkRegister,

    //   beforeEnter: authGuard,
    children: [
      {
        path: appRoute.register,
        name: "register",
        component: () => import("../views/App/Register.vue")
      },
      {
        path: appRoute.editProfile,
        name: "editProfile",
        beforeEnter: checkRegister,
        component: () => import("../views/App/Register.vue")
      },

      {
        path: appRoute.home,
        name: "licenseDetail",
        component: () => import("../views/App/licenseDetail/LicenseDetail.vue")
        // beforeEnter: checkRegister
      },
      {
        path: appRoute.licenseSelect,
        name: "home",
        beforeEnter: checkRegister,
        component: () => import("../views/App/Home.vue")
        //  redirect: `/:level/${appRoute.applyLicense}`
      },
      {
        path: appRoute.clubMember + `/:type`,
        name: "clubMember",
        beforeEnter: checkRegister,
        component: () => import("../views/App/club-member/index.vue")
      },
      {
        path: appRoute.clubMember + `/:type/add-player`,
        name: "addPlayer",
        component: () => import("../views/App/club-member/AddPlayer.vue")
        // component: () => import("../views/App/club-member/AddStaff.vue")
      },
      {
        path: appRoute.clubMember + `/:type/add-staff`,
        name: "addStaff",
        component: () => import("../views/App/club-member/AddStaff.vue")
      },
      {
        path: appRoute.clubMember + `/:type/join`,
        name: "joinTournament",
        component: () => import("../views/App/club-member/JoinTournament.vue")
      },
      {
        path: appRoute.clubMember + `/tournament/player/:id`,
        name: "tournamentPlayerList",
        component: () => import("../views/App/club-member/TournamentList.vue")
      },
      {
        path: appRoute.clubMember + `/tournament/staff/:id`,
        name: "tournamentStaffList",
        component: () => import("../views/App/club-member/TournamentList.vue")
      },
      {
        path: appRoute.clubMember + `/player/:id`,
        name: "editPlayer",
        component: () => import("../views/App/club-member/AddPlayer.vue")
      },
      {
        path: appRoute.clubMember + `/staff/:id`,
        name: "editStaff",
        component: () => import("../views/App/club-member/AddStaff.vue")
      },
      {
        path: appRoute.clubMember + `/member/card/:id`,
        name: "cardMember",
        component: () => import("../views/App/club-member/CardMember.vue")
      },
      // {
      //   path: "login",
      //   name: "appLogin",
      //   beforeEnter: authGuard,
      //   component: Login
      // },
      {
        path: appRoute.history,
        name: "history",
        beforeEnter: checkRegister,
        component: () => import("../views/App/history/index.vue")
      },
      {
        path: `/:level`,
        beforeEnter: authGuard,
        component: () => import("../views/App/apply-license/index.vue"),
        children: [
          {
            path: `${appRoute.applyLicense}`,
            beforeEnter: authGuard,
            component: () => import("../views/App/apply-license/index.vue"),
            children: [
              {
                path: "",
                name: "applyLicense",
                component: () =>
                  import("../views/App/apply-license/Overview.vue")
              },
              {
                path: appRoute.certificate,
                name: "certificate",
                component: () => import("../views/App/certificate/index.vue")
              },
              {
                path: appRoute.book,
                name: "book",
                component: () => import("../views/App/book/index.vue")
              },
              {
                path: appRoute.leadership,
                name: "leadership",
                component: () =>
                  import("../views/App/apply-license/leadership/index.vue")
              },
              {
                path: appRoute.planning,
                name: "planning",
                component: () =>
                  import("../views/App/apply-license/planning/index.vue")
              },
              {
                path: appRoute.organization,
                name: "organization",
                component: () =>
                  import("../views/App/apply-license/organization/index.vue")
              },
              {
                path: appRoute.staffing,
                name: "staffing",
                component: () =>
                  import("../views/App/apply-license/staffing/index.vue")
              },
              {
                path: appRoute.recruitment,
                name: "recruitment",
                component: () =>
                  import("../views/App/apply-license/recruitment/index.vue")
              },
              {
                path: appRoute.finance,
                name: "finance",
                component: () =>
                  import("../views/App/apply-license/finance/index.vue")
              },
              {
                path: appRoute.facilities,
                name: "facilities",
                component: () =>
                  import("../views/App/apply-license/facilities/index.vue")
              },
              {
                path: appRoute.teams,
                name: "team",
                component: () =>
                  import("../views/App/apply-license/teams/index.vue")
              },
              {
                path: appRoute.coaching,
                name: "coaching",
                component: () =>
                  import("../views/App/apply-license/coaching/index.vue")
              },
              {
                path: appRoute.playing,
                name: "playing",
                component: () =>
                  import("../views/App/apply-license/playing/index.vue")
              },
              {
                path: appRoute.playerPerformance,
                name: "playerPerformance",
                component: () =>
                  import("../views/App/apply-license/performance/index.vue")
              },
              {
                path: appRoute.health,
                name: "health",
                component: () =>
                  import("../views/App/apply-license/health/index.vue")
              },
              {
                path: appRoute.fitness,
                name: "fitness",
                component: () =>
                  import("../views/App/apply-license/fitness/index.vue")
              },
              {
                path: appRoute.psychology,
                name: "psychology",
                component: () =>
                  import("../views/App/apply-license/psychology/index.vue")
              },
              {
                path: appRoute.welfare,
                name: "welfare",
                component: () =>
                  import("../views/App/apply-license/welfare/index.vue")
              },
              {
                path: appRoute.education,
                name: "education",
                component: () =>
                  import("../views/App/apply-license/education/index.vue")
              },
              {
                path: appRoute.collaboration,
                name: "collaboration",
                component: () =>
                  import("../views/App/apply-license/collaboration/index.vue")
              },
              {
                path: appRoute.assessment,
                name: "assessment",
                component: () =>
                  import("../views/App/apply-license/assessment/index.vue")
              },
              {
                path: appRoute.rules,
                name: "rules",
                component: () =>
                  import("../views/App/apply-license/rules/index.vue")
              },
              {
                path: appRoute.results,
                name: "results",
                component: () =>
                  import("../views/App/apply-license/results/index.vue")
              }
            ]
          },
          {
            path: appRoute.member + `/:type`,
            name: "member",
            beforeEnter: checkRegister,
            component: () => import("../views/App/member/Member.vue"),
            children: [
              //   {
              //     path: "",
              //     name: "member",
              //     component: () => import("../views/App/member/Member.vue")
              //   },
              // {
              //     path: "add",
              //     name: "add",
              //     component: () => import("../views/App/member/AddMember.vue")
              // },
              // {
              //     path: "addplayer",
              //     name: "addplayer",
              //     component: () => import("../views/App/member/AddMember.vue")
              // },
              // {
              //     path: "addstaff",
              //     name: "addstaff",
              //     component: () => import("../views/App/member/AddMember.vue")
              // }
            ]
          },
          {
            path: appRoute.member + `/:type/add`,
            name: "member/add",
            component: () => import("../views/App/member/AddMember.vue")
          },
          {
            path: appRoute.guidelines,
            name: "guidelines",
            beforeEnter: authGuard,
            component: () => import("../views/App/Guidelines.vue")
          },
          {
            path: appRoute.member + `/:type/:id`,
            name: "member/edit",
            component: () => import("../views/App/member/AddMember.vue")
          },
        ]
      }
    ]
  }
];
