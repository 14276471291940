
import { Component, Vue } from "vue-property-decorator";
import { appRoute } from "@/shared/constants";
import { AppApiFactory } from "@/services/AppFactory";
import { ELicenseStatus } from "@/shared/api-interfaces";

const userService = AppApiFactory.user;

@Component({
  components: {}
})
export default class Menu extends Vue {
  isOpenMenu: boolean;
  isTabsIdxOptional: boolean;
  isLoading: boolean;
  status: ELicenseStatus | string;
  visible: boolean;
  levels: string;

  constructor() {
    super();

    this.isOpenMenu = false;
    this.isTabsIdxOptional = false;
    this.isLoading = false;
    this.status = ELicenseStatus.APPLIED;
    this.visible = true;
    this.levels = "";
  }

  get level() {
    return this.$route.params.level;
  }

  get lang() {
    return this.$store.state.global.language;
  }

  getLicenseStatus() {
    this.isLoading = true;
    userService
      .getLicense()
      .then(res => {
        const userLicenseLevel = res.data?.license_level.license_slug.toLowerCase();

        this.levels = userLicenseLevel;
        if (
          res.data?.status &&
          res.data?.status !== ELicenseStatus.APPLIED &&
          // this.level !== userLicenseLevel &&
          this.$route.path !== "/"
        ) {
          // this.$router.push(`/${userLicenseLevel}/${appRoute.applyLicense}`);
        }

        this.status = res.data?.status || ELicenseStatus.APPLIED;
      })
      .finally(() => (this.isLoading = false));
  }

  get menu() {
    if (this.$store.state.auth.isAppAuthen) {
      return [
        {
          label: "Home",
          labelTh: "หน้าหลัก",
          route: `${appRoute.home}`,
          name: "home",
          visible: true,
          disabled: false
        },
        {
          label: "Apply for license",
          labelTh: "สมัครใบอนุญาต",
          route: `/select-license`,
          //   route: `/${this.level}/${appRoute.applyLicense}`,
          name: "applyLicense",
          visible: true,
          disabled: this.isFirstTime
        },
        {
          label: "History",
          labelTh: "ประวัติ",
          //   route: `/${this.level}/${appRoute.history}`,
          route: `/${appRoute.history}`,
          name: "history",
          visible: true,
          disabled: this.isFirstTime
        },
        {
          label: "Member",
          labelTh: "สมาชิก",
          route: `/club-member/player`,
          name: "clubMember",
          visible: this.status === "APPLIED" ? true : false,
          disabled: this.isFirstTime
        },
        //  {
        //   label: "Member",
        //   labelTh: "สมาชิก",
        //   route: `/${this.levels}/member/player`,
        //   name: "member",
        //   visible: this.status === "APPLIED" ? true : false,
        //   disabled: this.isFirstTime
        // }
      ];
    } else {
      return [
        // {
        //   label: "Home",
        //   route: appRoute.home,
        //   name: "home"
        // }
        // {
        //   label: "History",
        //   route: appRoute.history,
        //   name: "history"
        // }
      ];
    }
  }

  get tabIdx(): number {
    const matchIdx = this.menu.findIndex(ele =>
      ele.route === "/"
        ? this.$route.path === ele.route
        : this.$route.path.includes(ele.route)
    );
    if (matchIdx === -1) {
      this.isTabsIdxOptional = true;
    } else {
      this.isTabsIdxOptional = false;
    }
    return matchIdx;
  }

  get isFirstTime() {
    return this.$store.state.auth.isFirstTime;
  }

  handleClick(path: string) {
    if (this.$route.path === path) return;
    this.$router.push(`${path}`);
  }

  mounted() {
    this.getLicenseStatus();
  }
}
