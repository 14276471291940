//import { ELevelSlug, SavePeriod } from "@/shared/api-interfaces";
import { AxiosInstance } from "axios";

const resource = "/member";

export default function(configedRequest: AxiosInstance) {
  return {
    async get() {
      const { data } = await configedRequest.get(`${resource}`);
      return data;
    },
    async createMember(id: string, body: any) {
      const { data } = await configedRequest.post(
        `${resource}/${id}/athlete/create`,
        body,
      );
      return data;
    },
    async editMember(academyId: string, id: string, body: any) {
      const { data } = await configedRequest.post(
        `${resource}/${academyId}/athlete/${id}/update`,
        body,
      );
      return data;
    },
    async deleteMember(academyId: string, id: string) {
      const { data } = await configedRequest.post(
        `${resource}/${academyId}/athlete/${id}/delete`
      );
      return data;
    },
    async createStaff(id: string, body: any) {
      const { data } = await configedRequest.post(
        `${resource}/${id}/staff/create`,
        body,
      );
      return data;
    },
    async editStaff(academyId: string, id: string, body: any) {
      const { data } = await configedRequest.post(
        `${resource}/${academyId}/staff/${id}/update`,
        body,
      );
      return data;
    },
    async deleteStaff(academyId: string, id: string) {
      const { data } = await configedRequest.post(
        `${resource}/${academyId}/staff/${id}/delete`
      );
      return data;
    },
    async joinTournament(academyId: string, body: any) {
      const { data } = await configedRequest.post(
        `${resource}/${academyId}/tournament/join`,
        body,
      );
      return data;
    },
    async unjoinTournament(academyId: string, body: any) {
      const { data } = await configedRequest.post(
        `${resource}/${academyId}/tournament/unjoin`,
        body,
      );
      return data;
    },
    async joinTournamentPlayer(academyId: string, id: string, body: any) {
      const { data } = await configedRequest.post(
        `${resource}/${academyId}/tournament/${id}/athelete/member/join`,
        body,
      );
      return data;
    },
    async joinTournamentStaff(academyId: string, id: string, body: any) {
      const { data } = await configedRequest.post(
        `${resource}/${academyId}/tournament/${id}/staff/member/join`,
        body,
      );
      return data;
    },
    async unjoinTournamentPlayer(academyId: string, id: string, body: any) {
      const { data } = await configedRequest.post(
        `${resource}/${academyId}/tournament/${id}/athelete/member/unjoin`,
        body,
      );
      return data;
    },
    async unjoinTournamentStaff(academyId: string, id: string, body: any) {
      const { data } = await configedRequest.post(
        `${resource}/${academyId}/tournament/${id}/staff/member/unjoin`,
        body,
      );
      return data;
    },
    async getMember(academy_id: string, id: string) {
      const { data } = await configedRequest.get(`${resource}/${academy_id}/athelete/${id}`);
      return data;
    },
    async getStaff(academy_id: string, id: string) {
      const { data } = await configedRequest.get(`${resource}/${academy_id}/staff/${id}`);
      return data;
    },
    async getAllMember(academy_id: string, page?: string, size?: string) {
      let url = `${resource}/${academy_id}/athelete`;
      const queryParams: string[] = [];
      if (page) queryParams.push(`page=${page}`);
      if (size) queryParams.push(`size=${size}`);
      if (queryParams.length > 0) {
        url += `?${queryParams.join('&')}`;
      }
      const { data } = await configedRequest.get(url);
      return data;
    },
    
    async getAllStaff(academy_id: string, page?: string, size?: string) {
      let url = `${resource}/${academy_id}/staff`;
      const queryParams: string[] = [];
      if (page) queryParams.push(`page=${page}`);
      if (size) queryParams.push(`size=${size}`);
      if (queryParams.length > 0) {
        url += `?${queryParams.join('&')}`;
      }
      const { data } = await configedRequest.get(url);
      return data;
    },
    async getAllTournament(academy_id: string) {
      const { data } = await configedRequest.get(`${resource}/${academy_id}/tournament-dropdwon`);
      return data;
    },
    async getTournamentById(academy_id: string) {
      const { data } = await configedRequest.get(`${resource}/${academy_id}/tournament`);
      return data;
    },
    async getTournamentPlayer(academy_id: string, id: string, page: string, size: string) {
      const { data } = await configedRequest.get(`${resource}/${academy_id}/tournament/${id}/athelete/member?page=${page}&size=${size}`);
      return data;
    },
    async getTournamentStaff(academy_id: string, id: string, page: string, size: string) {
      const { data } = await configedRequest.get(`${resource}/${academy_id}/tournament/${id}/staff/member?page=${page}&size=${size}`);
      return data;
    },
  };
}
