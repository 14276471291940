export const genderTitle = [
  { value: 1, text: "Mr." },
  { value: 2, text: "Mrs." },
  { value: 3, text: "Miss" }
];
export const genderTitleTh = [
  { value: 1, text: "นาย" },
  { value: 2, text: "นาง" },
  { value: 3, text: "นางสาว" }
];
export const genderItems = [
  { value: 1, text: "Male" },
  { value: 2, text: "Female" },
  { value: 3, text: "Mixed" }
];
export const genderItemsTh = [
  { value: 1, text: "ชาย" },
  { value: 2, text: "หญิง" },
  { value: 3, text: "ทีมผสม" }
];
export const genders = [
  { value: 1, text: "Male" },
  { value: 2, text: "Female" }
];
export const gendersTh = [
  { value: 1, text: "ชาย" },
  { value: 2, text: "หญิง" }
];

export const nationality = [
  "Thai",
  "English",
  "Others"
];

export const nationalityTh = [
  "ไทย",
  "อังกฤษ",
  "อื่นๆ"
];

// export const nationality = [
//   { value: 1, text: "Thai" },
//   { value: 2, text: "English" },
//   { value: 3, text: "Others" }
// ];

// export const nationalityTh = [
//   { value: 1, text: "ไทย" },
//   { value: 2, text: "อังกฤษ" },
//   { value: 3, text: "อื่นๆ" }
// ];

// export const genderItems = ["Male", "Female", "Mixed"];
// export const genderItemsTh = ["ชาย", "หญิง", "ทีมผสม"];
export const positionTeam = [
  { value: 1, text: "Head Coach" },
  { value: 2, text: "Assistant Coach" },
  { value: 3, text: "GK Coach" },
  { value: 4, text: "Fitness Coach" },
  {
    value: 5,
    text: "Other"
  }
];
export const positionTeamTh = [
  { value: 1, text: "หัวหน้าผู้ฝึกสอน" },
  { value: 2, text: "ผู้ช่วยผู้ฝึกสอน" },
  { value: 3, text: "ผู้ฝึกสอนผู้รักษาประตู" },
  { value: 4, text: "ฟิตเนสโค้ช" },
  {
    value: 5,
    text: "อื่นๆ"
  }
];

export const licenseItem = [
  { value: "G", text: "G" },
  { value: "GK1", text: "GK1" },
  { value: "GK2", text: "GK2" },
  { value: "C", text: "C" },
  { value: "B", text: "B" },
  { value: "A", text: "A" },
  { value: "PRO", text: "PRO" },
  { value: "None", text: "None" }
];

export const licenseItemTh = [
  { value: "G", text: "G" },
  { value: "GK1", text: "GK1" },
  { value: "GK2", text: "GK2" },
  { value: "C", text: "C" },
  { value: "B", text: "B" },
  { value: "A", text: "A" },
  { value: "PRO", text: "PRO" },
  { value: "None", text: "ไม่มี" }
];

export const groupItem = [
  "U21",
  "U20",
  "U19",
  "U18",
  "U17",
  "U16",
  "U15",
  "U14",
  "U13",
  "U12",
  "U11",
  "U10",
  "U9"
  // "U8",
  // "U7"
];

export const shirtNumber = Array.from({ length: 100 }, (_, index) => (index + 1).toString());

export const playerType = [
  { value: "Thai", text: "นักกีฬาไทย" },
  { value: "Foreign", text: "นักกีฬาต่างชาติ" }
];
export const staffType = ["บุคลากรไทย", "บุคลากรต่างชาติ"];
export const playerPositionTh = [
  { value: "GOALKEEPER", text: "ผู้รักษาประตู" },
  { value: "DEFENDER", text: "กองหลัง" },
  { value: "MIDFIELDER", text: "กองกลาง" },
  { value: "FORWARD", text: "กองหน้า" },
];

export const playerPosition = [
  { value: "GOALKEEPER", text: "Goalkeeper" },
  { value: "DEFENDER", text: "Defender" },
  { value: "MIDFIELDER", text: "Midfielder" },
  { value: "FORWARD", text: "Forward" },
];

export const religion = ["พุทธ", "คริสต์", "อิสลาม", "ฮินดู", "อื่นๆ"];
export const relationship = [
  "สามี",
  "ภรรยา",
  "บิดา",
  "มารดา",
  "ญาติ ",
  "พี่ชาย/น้องชาย",
  "พี่สาว/น้องสาว",
  "เพื่อน"
];

export const management = [
  "LEADERSHIP",
  "ORGANIZATION",
  "STAFFING",
  "FINANCE",
  "FACILITIES",
  "TEAMS",
];
export const coaching = [
  "RECRUITMENT",
  "PLAYER PERFORMANCE",
  "FITNESS",
  "ASSESSMENT",
  "RESULTS",
];
export const health = [
  "HEALTH",
  "PSYCHOLOGY",
  "WELFARE",
  "EDUCATION",
  "COLLABORATION",
];